@import "normalize.css";
@import "webflow.css";
@import "melohair.css";

.reveal {
  opacity: 0;
  transform: translateY(20px);
  transition: 1s cubic-bezier(0.5, 0, 0, 1);
  transition-property: opacity, transform;
}
.reveal.in {
  opacity: 1;
  transform: none;
}

@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-element {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out; /* Added smooth easing */
}

.fade-in-from-left {
  animation-name: fadeInFromLeft;
}

.fade-in-from-right {
  animation-name: fadeInFromRight;
}

.fade-in-from-top {
  animation-name: fadeInFromTop;
}

.fade-in-from-bottom {
  animation-name: fadeInFromBottom;
}
